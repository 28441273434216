@import url(https://fonts.googleapis.com/css?family=Press+Start+2P);
@charset "utf-8";
body {
  margin: 0;
  font-family: "Press Start 2P";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}




#landingScreen{
    background-color: #FFFFFF;
    height:100vh;
    display:grid;
}

#landingScreen > #landingScreenHeader {
    margin:auto;
}
